import type { AxiosInstance } from 'axios';

import getAuthString from './getAuthString';

const resetTokenForInstance = (axiosInstance: AxiosInstance) => {
  // eslint-disable-next-line no-param-reassign
  axiosInstance.defaults.headers.authorization = getAuthString();
};

export default resetTokenForInstance;
